.uploadLogo {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add_new_resident_back {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add_new_resident_background_color {
  background-color: white;
  width: 400px;
  border-radius: 5px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-top: 40px;
  padding-bottom: 40px;
}
.add_new_resident_name_img_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.add_new_resident_text {
  font-size: 16px;
  font-weight: 500;
}
.manager_profile_img {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  border: 2px solid #ebf4fe;
  padding: 1px;
}
.manager_name_text {
  font-size: 16px;
  font-weight: 800;
  margin-top: 11px;
}
.upload_photo_text {
  font-size: 11px;
  color: #1d7def;
  text-decoration: underline;
  margin-top: -9px;
}
.email_input_field {
  border: none;
  background: #eaeaea;
  padding: 7px;
  font-size: 13px;
  padding-left: 15px;
  border-radius: 5px;
  width: 100%;
  padding-right: 15px;
  margin-top: 2px;
}
.email_label {
  font-size: 13px;
  margin-top: 20px;
}

.phone_input_div {
  border: none;
  background: #ebf4fe;
  padding: 7px;
  font-size: 13px;
  padding-left: 15px;
  border-radius: 5px;
  width: 100%;
  padding-right: 15px;
  margin-top: 2px;
}
.phone_label {
  font-size: 13px;
  margin-top: 20px;
}
.phone_input_field_div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.phone_input_field {
  border: none;
  background: none;
  width: 100%;
}
.forest_cove_img_div {
  border: none;
  background: #ebf4fe;
  padding: 20px;
  font-size: 13px;
  padding-left: 15px;
  border-radius: 5px;
  width: 100%;
  padding-right: 15px;
  margin-top: 2px;
  display: flex;
  justify-content: center;
}

.upload_pdf_div {
  border: none;
  background: #ebf4fe;
  padding: 35px;
  font-size: 13px;
  padding-left: 15px;
  border-radius: 5px;
  width: 100%;
  padding-right: 15px;
  margin-top: 2px;
  display: flex;
  justify-content: center;
}
.upload_pdf_label {
  font-size: 13px;
  margin-top: 20px;
}
.remove_text {
  font-size: 11px;
  margin-top: 20px;
  text-decoration: underline;
  color: #dc3b3b;
}
.upload_pdf_field_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* .upload_pdf_div {
    border: none;
    background: none;
    width: 100%;
  } */

input:focus {
  outline: none;
}
.insurance-policy-btn {
  width: 100%;
  margin-top: 50px;
  background-color: #1d7def !important;
  color: #1d7def;
  font-weight: 500 !important;
  font-size: 15px !important;
  border-radius: 5px;
  padding: 5px;
  color: white;
}
.insurance_policy_background_color {
  background-color: white;
  border-radius: 5px;
  overflow: scroll;
  padding: 40px;
  width: 400px;
}
.input_fields_insurance_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* padding: 20px; */

  width: 100%;
}
.insurance_policy_field {
  width: 100%;
}

.insurance_policy_profile_save_button_div {
  width: 100%;
}
.current_logo_remove_div {
  display: flex;
  justify-content: space-between;
}
