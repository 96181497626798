.funUp {
    margin-top: 2%;
    height: 80%;
}
.messageBox {
    /* padding-top: 2%; */
    margin-bottom: 2% ;
}
/* .messageDet {
    background-color: ;
} */
/* .messageList {
    padding-bottom: 5%;
} */