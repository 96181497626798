.main {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100vh!important;
}
.img_div {
  display: flex;
  justify-content: center;
}
.forest_img {
  width: 100px;
}
.white_box {
  width: 300px;
  background: white;
  border-radius: 10px;
  padding-left: 20px;
  box-shadow: 0 0px 7px rgb(0 0 0 / 20%);
  padding-right: 20px;
  padding-top: 34px;
  padding-bottom: 30px;
}
.welcome_text {
  font-weight: 400;
}
.kepah_img {
  width: 130px;
}
/* .input_create_password {
  border: none !important;
  background-color: transparent !important;
  padding: 0 !important;
  font-size: 12px !important;
  width: 90%!important;
} */
.input_div {
  border: 1px solid #ebf4fe;
  display: flex;
  justify-content: space-around;
  padding: 5px;
  border-radius: 5px;
  background-color: #ebf4fe;
  width: 100%;
}
.save_btn {
  width: 100%;
  margin-top: 10%;
  background-color: #1D7DEF!important;
  border: none!important;
  font-weight: 500!important;
  font-size: 15px!important;
}

.all_texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.label_create_password {
  font-size: 12px;
}
.security_text {
  font-size: 22px;
  font-weight: bold;
  padding-top: 10%;
}
.email {
  margin-top: -5%;
  font-size: 15px;
}

.dot {

    border: 1px solid;
    width: 1px;
    height: 1px;
    position: absolute;
    margin-top: 13px;
    margin-left: 8px;
}
