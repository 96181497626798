.incident_action_main_div {
    padding: 20px;
    height: 100vh;
    display: flex;
    background-color: #EAEAEA;
    width: 80%;
  }
  .incident_action_main_page {
    background-color: white;
    padding: 20px;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    padding-bottom: 7%;
    width: 100%;
  }
  .incident_back{
      display: flex;
      width:100%;
  }
  .vendorAdd {
    background-color: white !important;
    height: 65vh;
    border-radius: 10px;
    overflow: hidden;
    width: 55vh !important;
    margin-left: 30%;
    margin-top: 7%;
  }

  .vendorEditPage {
    background-color: white !important;
    height: 90vh;
    border-radius: 10px;
    width: 55vh !important;
    margin-left: 30% !important;
    margin-top: 1%;
  }