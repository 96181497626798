.list_of_officer_main_div {
  padding: 20px;
}
.list_of_officers_main_page {
  background-color: white;
  padding: 14px;
  height: 450px;
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 7%;
  width: 80%;
}

.commentModal {
  padding: 5px;
}

.commentModalDiv {
  padding: 25px;
}

.incidentCommentInput {
  width: 100%;
}

.all_names_profiles_license_div_main_page {
  overflow-y: auto;
  width: 100%;
  height: 300px;
  overflow-x: hidden;
}

.bottom_border_vehicle_page {
  width: 100%;
  height: 1px;
  left: 383px;
  top: 272px;
  background: rgba(196, 196, 196, 0.25);
}
.calendar_incident {
  width: 15%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 13px;
  margin-bottom: 13px;
}
.incident_all_reports_div {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}
.incident_report_name_time_div {
  width: 50%;
}
.incident_report_no_name {
  text-decoration: underline;
  font-size: 15px;
  cursor: pointer;
}
.incident_report_time_date {
  font-size: 12px;
  color: #717270;
  margin-left: 14px;
}
.incident_main_report {
  font-size: 13px;
  color: #717270;
  margin-top: 8px;
}
.incident_share_report_page_div {
  display: flex;
  justify-content: space-evenly;
  width: 20%;
}
.round_background {
  box-shadow: 0px 0px 0px 4px #e4ffd8;
  border-radius: 20px;
}
.down_arrow_incident {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3%;
}
.scroll_div_incident_page {
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  padding-bottom: 40px;
}
.round_background_2 {
  box-shadow: 0px 0px 0px 4px #ffdada;
  border-radius: 20px;
}

.round_background_3 {
  box-shadow: 0px 0px 0px 4px #fff7dc;
  border-radius: 20px;
}

.action_taken_input {
  width: 300px;
  border-radius: 7px;
  border: none;
  padding: 5px;
  padding-left: 10px;
  font-size: 12px;
  background-color: #ebf4fe;
  margin-top: 5px;
}

.action-taken_text {
  font-size: 12px;
}
.action_taken_div {
  display: flex;
  flex-direction: column;
}
.incident_type_div {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
input[type="radio"] {
  background-color: red !important;
}
.police-matter-buttons-div {
  display: flex;
}
.yes-text {
  margin-left: 7px;
  font-size: 13px;
}
.no_button {
  margin-left: 15px;
}
.police_matter_div {
  margin-top: 5px;
}
.infraction_given_div {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 20px;
}
.additional_report_div {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}
.incident_report_div {
  display: flex;
}
.incident_report_popup_background {
  background: #fff;
  width: 323px;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 0 4px rgb(0 0 0 / 20%);
  margin-top: 30px;
}
.incident_reporter_div {
  margin-top: 5px;
}
.incident_reporter_text {
  font-size: 13px;
  font-weight: bold;
}
.reporter_name {
  font-size: 13px;
  margin-left: 5px;
}

.incident_report_status_div {
  font-size: 12px;
  position: relative;
}
.status_report_div {
  width: 80px;
  background: #ebf4fe;
  padding: 6px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.status_report_choose {
  margin-top: 5px;
  width: 120px;
  background: #ebf4fe;
  padding: 6px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 1000;
  height: 25px;
  cursor: pointer;
  box-shadow: 0px 0px 10px 3px #eaeaea;
}
.status_report_choose1 {
  margin-top: 35px;
  width: 120px;
  background: #ebf4fe;
  padding: 6px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 1000;
  height: 25px;
  cursor: pointer;
  box-shadow: 0px 0px 10px 3px #eaeaea;
}

.status_report_choose2 {
  margin-top: 65px;
  width: 120px;
  background: #ebf4fe;
  padding: 6px;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  z-index: 1000;
  height: 25px;
  box-shadow: 0px 0px 10px 3px #eaeaea;
  cursor: pointer;
}
.up_arrow_img {
  width: 10px;
}
.download_btn_form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
}
.download_btn_incident {
  width: 180px;
  background-color: #fff !important;
  border-color: #1d7def !important;
  color: #1d7def;
  /* border: none !important; */
  font-weight: 500 !important;
  font-size: 15px !important;
  /* margin-top: 30px; */
  border-radius: 5px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 90px; */
}
.download_icon_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.create_new_incident_btn {
  width: 200px;
  background-color: #fff !important;
  border-color: #1d7def !important;
  color: #1d7def;
  /* border: none !important; */
  font-weight: 500 !important;
  font-size: 15px !important;
  /* margin-top: 30px; */
  border-radius: 5px;
  padding: 5px;
}
.download_text {
  margin-left: 10px;
}
.incident_report_div_main_page {
  display: flex;
  justify-content: space-between;
}
.download_create_btns {
  display: flex;
}
