.list_of_officers_main {
  background-color: white;
  padding: 14px;
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 7%;

}
.list_of_officers_manage_div {
  display: flex;
  justify-content: space-between;
}
.manage_officers_text {
  font-size: 13px;
  text-decoration: underline;
  color: #257cff;
  cursor: pointer;
}
.list_of_officers_text {
  font-size: 17px;
}

.list_of_vehicle_sc {
  overflow-y: auto !important;
}


.officer_text_list {
  width: 50%;
  font-size: 12px;
  /* margin: 10px; */
}
.license_text {
  width: 50%;
  font-size: 14px;
}
.user_name {
  font-size: 12px;
  text-decoration: underline;
  margin-left: 11px;
  color: #257cff;
}
.profile_img {
  width: 30px;
  border-radius: 20px;
}
.profile_name {
  width: 50%;
}
.bottom_border {
  border: 1px solid #ebf4fe;
}
.officer_license_number_white_back {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  background-color: white;
  padding: 5px;
  padding-left: 10px;
}
.officer_license_number_blue_back {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  background-color: #ebf4fe;
  padding: 5px;
  padding-left: 10px;
}

.all_names_profiles_license_div {
    overflow-y: auto;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}

.license_scroll_div{
    height: 100%;
    width: 100%;
}
.license_number_div{
    margin-left: 15px;
}