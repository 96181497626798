.policy_main_div {
    display: flex;
    background-color: #EAEAEA;
    width: 80%;
  }
  .policy_main_page {
    /* background-color: white;
    padding: 14px;
    height: 100%; */
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .policy_back{
      display: flex;
      width:100%;
  }

  