.main-officer_schedule {
    display: flex;
    width: 100%;
    background: #eaeaea;
    height: 100%;
    overflow: scroll;
  }
  
  .update_lease_information {
    display: flex;
    background: #fff;
    width: 80%;
    flex-direction: column;
    margin: 20px;
    overflow: scroll;
  }
  
  .officer_management_calendar {
    width: 40%;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 10px;
    padding-right: 10px;
    overflow: hidden;
  }
  
  .list_of_appartments {
    height: 400px;
    width: 100%;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 10px;
    padding-right: 20px;
  }
  .history_of_payments {
    height: 400px;
    width: 100%;
    padding-top: 10px;
    padding-left: 20px;
    padding-bottom: 20px;
    padding-right: 20px;
  }
  
  .securityCompanyAdd {
    background-color: white !important;
    height: 70vh !important;
    border-radius: 10px;
    overflow: hidden;
    width: 45vh !important;
    margin-left: 30%;
    margin-top: 10%;
    padding-bottom: 15% !important;
  }

  .bgOfAdd {
    display: flex;
    background: #eaeaea;
    width: 80%;
    flex-direction: column;
    overflow: scroll;
  }

    
  .list_of_securityCompany{
    height: 60vh;
    width: 100%;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 10px;
    padding-right: 20px;
    overflow-y: auto;
  }

.row_alternate:nth-child(odd) {
    background-color: white; /* or any color you want for odd rows */
  }
  
.row_alternate:nth-child(even) {
    background-color: #ebf4fe; /* or any color you want for even rows */
  }

  .scrollClass {
    overflow-y: auto;
  }