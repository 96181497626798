.ohI {
    background-color: red !important;
}

.radioButton {
    font-size: 12px;
}

.radioButton {
    display: flex;
    align-items: center;
}

.bg_of_drop_xx {
    padding-top: 2px;
    margin-left: 5px !important;
}

.uploadImg {
    width: 35px;
    height: 35px;
    margin-left: 114px;
}

.phone_input_field_div_xx {
    background: #ebf4fe;
    margin-top: 5px;
    padding-top: 18px !important;
    padding-bottom: 18px !important;
}

.radioLabel {
    padding-left: 5px !important;
}

.spanGo {
    color: #1D7DEF;
    cursor: pointer;
    text-decoration: underline;
}

.textOnTop {
    margin-left: 48px;
}

.textO {
    display: flex;
    justify-content: center;
    justify-content: flex-end;
    margin-right: -50px;
}

.mgmg {
    margin-left: 30px;
}
.lastPad {
    padding-bottom: 20px;
}

.title_of_extra {
    margin-top: 15px !important;
}

.buttonStyleAddIncident {
    width: 100%;
    border-radius: 5px;
    margin-left: 6px;
}

.inputWidth {
    width: 85% !important;
}

.incident_report_label {
    font-size: 13px;
    margin-top: 15px;
    
}

.header_incident_report {
    padding-top: 10px !important;
}

.header_incident_report_party {
    padding-top: 30px !important;
}

.incident_report_label_type {
    font-size: 13px;
    padding-top: 0px !important;
}


.incidentInfoTitle {
    margin: 0px !important;
    padding: 0px !important;
    
}

.incidentInfoTitleMg {
    margin: 0px !important;
    padding: 0px 0px 4px 0px !important;
    font-size: 18px !important;
    font-weight: 400 !important;
}

.incidentInfoTitleParty {
    margin: 0px !important;
    padding: 0px 0px 0px 0px !important;
    font-size: 18px !important;
    font-weight: 400 !important;
}

.bg_of_drop {
    border: none;
    background: #ebf4fe;
    padding: 7px 7px 7px 10px !important;
    font-size: 14px;
    border-radius: 5px;
    width: 100%;
    padding-right: 15px;
    margin-top: 2px;
}

.phone_input_field_xx{
    border: none !important;
    width: 100% !important;
    background: #ebf4fe;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    
    background-image: url('../../../assets/down.svg');
    background-size: 12px 12px;
    background-repeat: no-repeat;
    background-position: right 15px center;
    padding-right: 30px;
}


.phone_input_field_date {
    border: none !important;
    width: 100% !important;
    background: #ebf4fe;
    background-position: right 5px center;
    padding-right: 12px;
}

.titleDropdown {
    border: none;
    background: none; /* To remove the background color on some browsers */
    outline: none; /* To remove the outline when the element is focused */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.title_add_incident_dd {
    margin-left: 12px !important;
}
.upload_image_text_tow {
    font-size: 16px;
}
.buttonStyleAddTow {
    width: 100%;
    margin-top: 15px;
}

.anythingNew{
    margin-top: 20% !important; 
}