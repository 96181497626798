.resident_personal_div {
    z-index: 1000;
}

.resident_personal_info {
    width: 400px !important;
    border-radius: 0px, 7px, 7px, 0px !important;
    background-color: #fff !important;
    margin: 0;
}

.occupants_scroll_container {
    max-height: 400px; /* Adjust the height as needed */
    overflow: hidden;
}
  
.occupants_scroll_wrapper {
    overflow-y: auto;
    width: 100%;
    height: 15vh !important;
}

.content_padding {
    padding-left: 20px;
    padding-bottom: 20px;
}

.prof_info {
    padding-left: 4%;
}

.prof_info_occupants {
    padding-left: 4%;
    margin: 0;
    font-size: 16px;
    padding-top: 5px !important;
}

.border_div_area {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.prof_info_border {
    width: 201px;
    height: 1px;
    flex-shrink: 0;
    background: rgba(196, 196, 196, 0.25);
}

.profile_img_resident_info {
    width: 64px !important; 
    height: 64px !important;
    border-radius: 63px !important;
}

/* .prof_image_occupants_div {
    display: flex;
    justify-content: center;
    align-items: center;
} */

.profile_img_occupants_info {
    width: 32px !important; 
    height: 32px !important;
    border-radius: 63px !important;
    margin-top: 25% !important;
}

/* .occupant_title {
    background-color: rosybrown !important;
} */

.prof_info_txt {
    margin: 0;
    padding: 0;
}


.prof_info_txt_occupant_show {
    margin-top: -15px !important;
    padding: 0 !important;
}

.prof_info_txt_name_occupant {
    color: #257CFF;
    
}

.resident_info_title_text {
    color: #131313;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
}

.resident_info_title_occupants {
    color: #131313;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: white;
}

.resident_info_text_image {
    display: flex;
    flex-direction: col;
}

.occupants_info_text_image {
    display: flex;
    flex-direction: col;
}


.apt_no {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px 7px 0px 0px !important;
    background: #EAEAEA;
    padding-top: 5%;
    height: 50px !important;
}

.apt_no_text {
    color: #131313;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.up_arrow {
    display: flex;
    align-items: center;
    justify-content: center;
}

.up_arrow_img {
    margin: 0;
    padding: 0;
    display: block;
    width: 20px !important;
}