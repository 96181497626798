.quick_rent_roll_main {
  background-color: white;
  padding: 14px;
  height: 400px;
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 1%
}

.trespass_sc {
  overflow-y: auto !important;
  overflow-x: hidden;
}
.list_of_officers_manage_div {
  display: flex;
  justify-content: space-between;
}
.manage_officers_text {
  font-size: 13px;
  text-decoration: underline;
  color: #257cff;
}
.list_of_officers_text {
  font-size: 17px;
}
.quick_rent_roll {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  margin-top: 10px;
  padding: 10px;
}
.officer_text_list {
  width: 50%;
  font-size: 12px;
  /* margin: 10px; */
}
.resident_text {
  font-size: 12px;
}
.user_name {
  font-size: 12px;
  text-decoration: underline;
  margin-left: 11px;
  color: #257cff;
}
.profile_img {
  width: 30px;
  border-radius: 20px;
}
.profile_name {
  width: 50%;
}
.bottom_border {
  border: 1px solid #ebf4fe;
}
.quick_rent_roll_white_back {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  background-color: white;
  padding: 5px;
  padding-left: 10px;
}
.quick_rent_roll_blue_back {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  background-color: #ebf4fe;
  padding: 5px;
  padding-left: 10px;
}

.all_names_profiles_rent_div {
  overflow-y: auto;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

.rent_scroll_div {
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.license_number_div {
  margin-left: 15px;
}
.apt_vehicle_div {
  display: flex;
  justify-content: space-between;
  width: 50%;
}
.resident_div {
  width: 50%;
}
.apt_text{
  font-size: 13px;
}
.vehicle_text {
  font-size: 13px;
}

.quick_input_div {
  margin-top: 30px;
}
.quick_input {
  width: 130px;
  border: 1px solid #aeaeae;
  font-size: 12px;
  padding: 5px;
  border-radius: 3px;
  color: #aeaeae;
  padding-left: 11px;
}
.apt_vehicle_no_div {
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.apt_no{
  font-size: 13px;
}
.veh_no {
  font-size: 13px;
  margin-right: 25px;
}