.visitor_personal_div {
    z-index: 1000;
    width: 420px !important;
    background-color: white;
}

.up_arrow_visitor {
    transform: rotate(-90deg);
}

.warningSubmit {
    margin-left: 1px !important;
    margin-right: 1px !important;
    margin-top: 10px;
}

.warBut {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    border-radius: 7px;
}

.crimTre {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #257CFF
}

.warningButton {
    background-color: #FB8787;
    width: 350px;
    font-weight: 700;
}

.infraButton {
    background-color: white;
    width: 350px;
    font-weight: 700;
    color: black !important;
}

.reportButton {
    background-color: blue;
    width: 350px;
    font-weight: 700;
}


.visitor_personal_info {
    width: 400px !important;
    border-radius: 0px, 7px, 7px, 0px !important;
    background-color: #fff !important;
    margin: 0;
}

.content_padding_visitor {
    padding-left: 20px;
    padding-bottom: 20px;
    margin-top: -45px;
}

.arrowTop {
    margin-top: 13%
}

.prof_info_visitor {
    padding-left: 25px !important;
}

.prof_info_occupants {
    padding-left: 4%;
    margin: 0;
    padding-top: 0;
}

.border_div_area {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.prof_info_border {
    width: 201px;
    height: 1px;
    flex-shrink: 0;
    background: rgba(196, 196, 196, 0.25);
}

.profile_img_visitor_info_img {
    width: 120px !important;
    height: 120px !important;
    border-radius: 64px;
}


/* .prof_image_occupants_div {
    display: flex;
    justify-content: center;
    align-items: center;
} */

.profile_img_occupants_info {
    width: 32px !important; 
    height: 32px !important;
    border-radius: 63px !important;
    margin-top: 25% !important;
}

/* .occupant_title {
    background-color: rosybrown !important;
} */

.prof_info_txt_visitor {
    margin: 0;
    padding: 0;
}

.prof_info_txt_name {
    margin: 0;
    padding: 0;
    color: #257CFF;
}

.resident_info_title_text {
    color: #131313;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 20px;
}

.resident_info_title_occupants {
    color: #131313;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: white;
}

.resident_info_text_image {
    display: flex;
    flex-direction: col;
}

.occupants_info_text_image {
    display: flex;
    flex-direction: col;
}


.top_visitor_space {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px 7px 0px 0px !important;
    background: #EAEAEA;
    padding-top: 5%;
    height: 60px !important;
}

.apt_no_text {
    color: #131313;
    font-family: Roboto;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.up_arrow {
    display: flex;
    align-items: center;
    justify-content: center;
}

.up_arrow_img {
    margin: 0;
    padding: 0;
    display: block;
    width: 20px !important;
}