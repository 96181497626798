.rent_main_div {
  padding: 20px;
  height: 100vh;
  display: flex;
  background-color: #eaeaea;
  width: 80%;
}
.incident_action_main_page {
  background-color: white;
  padding: 20px;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 7%;
  width: 100%;
}
.incident_back {
  display: flex;
  width: 100%;
}
.incident_back_popup{
  background-color: black;
}