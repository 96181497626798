.maintenance_main_div {
  height: 100vh;
  display: flex;
  background-color: #eaeaea;
  width: 80%;
  overflow: hidden;
}
.maintenance_profile_main_page {
  width: 30%;
}
.request_page_main_div {
  width: 70%;
}

.list_of_maintenance_request {
  height: 50vh !important;
  width: 100%;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
  padding-right: 20px;
  overflow-y: scroll;
}

.list_of_maintenance_request_person {
  height: 30vh !important;
  width: 100%;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
  padding-right: 20px;
}


.main-officer_schedule {
  display: flex;
  width: 100%;
  background: #eaeaea;
  height: 100%;
  overflow: scroll;
}

.update_lease_information {
  display: flex;
  background: #fff;
  width: 80%;
  flex-direction: column;
  margin: 20px;
  overflow: scroll;
}

.officer_management_calendar {
  width: 40%;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
  padding-right: 10px;
  overflow: hidden;
}

.list_of_appartments_lease {
  height: 400px;
  width: 100%;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 10px;
  padding-right: 20px;
}
.history_of_payments {
  height: 400px;
  width: 100%;
  padding-top: 10px;
  padding-left: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
}
