.incident_action_main_div {
    padding: 20px;
    height: 100vh;
    display: flex;
    background-color: #EAEAEA;
    width: 80%;
  }
  .incident_action_main_div2 {
    padding: 20px;
    height: 100vh;
    display: flex;
    background-color: #EAEAEA;
    width: 80%;
  }
  .incident_action_main_page {
    background-color: white;
    padding: 20px;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    padding-bottom: 7%;
    width: 100%;
  }

.incident_action_main_page2 {
  background-color: white;
  padding: 20px;
  height: 50vh;
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 7%;
  width: 100%;
  margin-top: 25vh;
}

.incident_action_main_page3 {
  background-color: white;
  padding: 20px;
  height: 68vh;
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 7%;
  padding-top: 2.5%;
  width: 30% !important;
  margin-top: 15vh;
  margin-left: 35%;
  overflow-y: auto;
}

.buttonStyleAddAuto {
  width: 100%;
  margin-top: 15px;
}

.incident_back{
  display: flex;
  width:100%;
}
.newFlow {
  overflow-y: auto !important;
}