.imagePart {
    width: 25%;
}

.messagePart {
    width: 50%;
}


.messageStyle2 {
    display: flex;
    flex-direction: row;
    background-image: url(../../../assets/messagebg2.svg);
    background-repeat: no-repeat;
    background-size: contain;
    width: 100%;
    margin-right: 2% !important;
    height: auto;

}

.datePart {
    width: 25%;
    margin: 0;
    padding-right: 10px;
}
.message1 {
    width: 10%;
}
.message2 {
    width: 90%;
    margin-left: -5%;
}
.messageStyle {
    display: flex;
    flex-direction: row;
    /* background-color: red; */
}
.bgMessage {
    background-color: white;
    width: 400px;
    border-radius: 5px;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
}

.bgMessage2 {
    background-color: white;
    width: 400px;
    border-radius: 5px;
    /* overflow: scroll; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    padding-bottom: 20px;
}

.imagePart2 {
    width: 15%;
    margin: 0;
    padding: 0;
}

.messagePart2 {
    width: 65%;
    margin: 0;
    padding: 0;
    height: auto;
}

.datePart2 {
    width: 20%;
    margin: 0;
    padding: 0;
}

.messageDet {
    background-image: url(../../../assets/messagebg.svg);
    background-repeat: no-repeat;
    background-size: cover;
    margin-left: 1% !important;
    width: 100%;
    height: auto;
}

.messageDet2 {
    /* margin-right: 2% !important; */
    width: 100%;
    height: auto;
    margin-right: 5%;
}

.messageStyle2 {
    display: flex;
    flex-direction: row;
    
    width: 100%;
    /* margin-right: 2%; */
    height: auto;
}

.imagePart3 {
    width: 15%;
    margin: 0;
    padding: 0;
    flex: 0 0 auto;
}

.datePart3 {
    width: 20%;
    margin: 0;
    padding: 0;
    flex: 0 0 auto;
}

.messagePart3 {
    width: 64%;
    margin: 0;
    padding: 0;
    /* margin-right: 6%; */
    margin-bottom: 2%;
    flex: 1 1 auto;
}
/* .fullCov {
    background-color: blue;
} */
.textMessageClass {
    background-image: url(../../../assets/messagebg2.svg);
    background-repeat: no-repeat;
    background-size: cover;
    padding-left: 3%;
    padding-right: 15px; /* Add some right padding to create space between text and image */
    height: 100%;
    display: flex;
    align-items: center;
    word-wrap: break-word; /* Allow long words to break and wrap to the next line */
}
