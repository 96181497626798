.manage_officer_schedule_main {
  background-color: white;
  padding: 30px;
  height: 400px;
  border-radius: 10px;
  overflow-y: scroll;
  padding-bottom: 7%;
  overflow-x: hidden;
  border-radius: 7px !important;
   position: relative!important;
}

.manage_officer_schedule_input_btn {
  display: flex;
  justify-content: space-between;
}
.list_of_maintenance_off {
  overflow-y: auto;
}


.list_of_maintenance_off .row_alternate:nth-child(odd) {
  background-color: white; /* or any color you want for odd rows */
}

.list_of_managers_div .row_alternate:nth-child(even) {
  background-color: #ebf4fe; /* or any color you want for even rows */
}

.containerMaintenance {
  padding: 0px !important;
}

.rowMaintenance {
  padding: 0px !important;
  margin: 0px !important;
  border-radius: 3px;
}

.colMaintenance {
  padding-left: 0px !important;
  margin: 0px !important;
}

.manage_officer_schedule_text {
  font-size: 17px;
}
.manage_officer_schedule_input_div {
  display: flex;
}
.manage_officer_schedule_input {
  width: 200px;
  margin-right: 12px;
  border-radius: 3px;
  border: 1px solid #aeaeae;
  padding: 5px;
  padding-left: 10px;
  font-size: 12px;
}
.search_btn {
  background: #1d7def;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}

.manage_officers_schedule_text {
  font-size: 13px;
  text-decoration: underline;
  color: #257cff;
  cursor: pointer;
}
.calendar {
  width: 22%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 13px;
  margin-bottom: 13px;
}
.chart {
  width: 1250px;
  position: absolute;
  overflow: scroll;
  /* height: 180px; */
}
