.maintenance_main_div {
  height: 100vh;
  display: flex;
  background-color: #eaeaea;
  width: 80%;
  overflow: hidden;
}
.maintenance_profile_main_page {
  width: 30%;
}
.request_page_main_div {
  width: 70%;
}

.col_maintenance_name {
  width: 25%;
}

.col_maintenance_apt {
  width: 20%;
}
.col_maintenance_tenants {
  width: 20%;
}
.col_maintenance_email {
  width: 25%;
}
.col_maintenance_edit{
  width: 3%;
}