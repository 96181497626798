.criminal_main_div {
  padding: 20px;
}
.criminal_manage_div {
  background-color: white;
  padding: 14px;
  height: 450px;
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 7%;
  width: 100%;
}

.criminal_input_div {
  display: flex;
  margin-top: 10px;
  align-items: center;
}

.criminal_input {
  width: 300px;
  margin-right: 12px;
  border-radius: 3px;
  border: 1px solid #aeaeae;
  padding: 7px;
  padding-left: 10px;
  font-size: 12px;
}
.criminal_search_btn {
  background: #1d7def;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  padding: 9px;
}

.save_btn_add_criminal {
  width: 250px;
  background-color: #fff !important;
  border-color: #1d7def !important;
  color: #1d7def;
  /* border: none !important; */
  font-weight: 500 !important;
  font-size: 15px !important;
  /* margin-top: 30px; */
  border-radius: 5px;
  padding: 5px;
}

.form_inline_criminal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
}

.officer_license_number_criminal {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  padding: 5px;
  padding-left: 10px;
  margin-top: 7px;
}
.officer_text_list_criminal {
  width: 30%;
  font-size: 14px;
  /* margin: 10px; */
}
.license_text_criminal {
  width: 23%;
  font-size: 14px;
}

.criminal_trespass_text {
  width: 30%;
  font-size: 14px;
}

.criminal_main_date {
  width: 30%;
  font-size: 14px;
}

.all_names_profiles_license_div_criminal {
  overflow-y: auto;
  width: 100%;
  height: 300px;
  overflow-x: hidden;
}

.user_name_criminal {
  font-size: 12px;
  text-decoration: underline;
  margin-left: 11px;
  color: #257cff;
}
.profile_img_criminal {
  width: 30px;
  border-radius: 20px;
}
.profile_name_criminal {
  width: 30%;
}
.bottom_border_criminal {
  border: 1px solid #ebf4fe;
}
.officer_license_number_white_back_criminal {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  background-color: white;
  padding: 5px;
  padding-left: 10px;
}
.name_text_main_page {
  width: 40%;
  font-size: 12px;
}
.date_of_birth_text {
  width: 30%;
  font-size: 14px;
}
.date_of_birth_main_page {
  width: 30%;
  font-size: 14px;
}
.profile_name_criminal_page {
  width: 40%;
}

.officer_license_number_blue_back_main_page {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  background-color: #ebf4fe;
  padding: 5px;
  padding-left: 10px;
}
.all_criminal_trespass_lists {
  overflow-y: auto;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  padding-bottom: 20%;
}
.owner_name_vehicle{
  font-size: 14px;
}

.vehicleOwner {
  font-size: 14px !important; 
  width: 20% !important;
}

.vehiclePhone {
  font-size: 14px !important; 
  width: 20%;
}

.vehicleNameX {
  font-size: 14px !important; 
  width: 20%;
  color: #1d7def
}

.vehicleNameX2 {
  font-size: 14px !important; 
  width: 20%;
}

.vehicleNumber {
  font-size: 14px !important; 
  width: 20% !important;
}

.vehicleTag {
  font-size: 14px !important; 
  width: 20%;
}

.vehicleInfo {
  background-color: white !important;
  z-index: 1000 !important;
  width: 40vh;
  padding-left: 10px;
}

.vehicleScroll {
  overflow-y: auto;
  height: 100vh !important;
}

.borderVehicleModal {
  border: 1px #ebf4fe solid;
}

.playRound {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: red;
}

.bgRound {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}