.rent_input {
  width: 160px;
  margin-right: 12px;
  border-radius: 3px;
  border: 1px solid #aeaeae;
  padding: 7px;
  padding-left: 10px;
  font-size: 12px;
}
.list_of_apt_sc {
  overflow-y: auto;
  width: 100%;
  height: 35vh;
}
.criminal_search_btn {
  background: #1d7def;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  padding: 9px;
}

.add_new_resident_btn {
  width: 250px;
  background-color: #fff !important;
  border-color: #1d7def !important;
  color: #1d7def;
  /* border: none !important; */
  font-weight: 500 !important;
  font-size: 15px !important;
  /* margin-top: 30px; */
  border-radius: 5px;
  padding: 5px;
}
.add_new_resident_btn:hover {
  width: 250px;
  background-color: #fff !important;
  border-color: #1d7def !important;
  color: #1d7def;
  /* border: none !important; */
  font-weight: 500 !important;
  font-size: 15px !important;
  /* margin-top: 30px; */
  border-radius: 5px;
  padding: 5px;
}

.form_inline_criminal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
}

.officer_license_number_criminal {
  display: flex;
  /* justify-content: space-between; */
  width: 100%;
  padding: 5px;
  padding-left: 10px;
  margin-top: 7px;
}
.officer_text_list_criminal {
  width: 30%;
  font-size: 14px;
  /* margin: 10px; */
}
.license_text_criminal {
  width: 23%;
  font-size: 14px;
}

.apartment_edit {
  width: 5%;
}

/* .criminal_trespass_text {
      width: 30%;
      font-size: 14px;
    } */

/* .criminal_main_date {
      width: 30%;
      font-size: 14px;
    } */

.all_names_profiles_license_div_criminal {
  overflow-y: auto;
  width: 100%;
  height: 300px;
  overflow-x: hidden;
}

.user_name_criminal {
  font-size: 12px;
  text-decoration: underline;
  margin-left: 11px;
  color: #257cff;
}
.profile_img_criminal {
  width: 30px;
  border-radius: 20px;
}
.profile_name_criminal {
  width: 30%;
}
.bottom_border_criminal {
  border: 1px solid #ebf4fe;
}
.officer_license_number_white_back_criminal {
  display: flex;
  width: 100%;
  background-color: white;
  padding: 5px;
  align-items: center;
  padding-left: 10px;
}
/* .name_text_main_page {
      width: 40%;
      font-size: 12px;
    }
    .date_of_birth_text {
      width: 30%;
      font-size: 14px;
    } */
/* .date_of_birth_main_page {
      width: 30%;
      font-size: 14px;
    } */

.list_of_apartments_main_div {
  display: flex;
  width: 100%;
  /* background-color: #ebf4fe; */
  padding: 5px;
  padding-left: 10px;
  align-items: center;
}
.all_list_of_apartments_list {
  overflow-y: auto;
  width: 100%;
  height: 280px;
  overflow-x: hidden;
  padding-bottom: 20%;
}

.apartment_page_text {
  width: 30%;
  font-size: 14px !important;
}
.apt_num_text_resident {
  width: 15%;
  font-size: 14px !important;
}
.apt_number_apartment {
  width: 25%;
  font-size: 14px !important;
}
.apartment_rent_amount {
  width: 25%;
  font-size: 14px !important;
}
.apartment_status {
  width: 15%;
  font-size: 14px !important;
}
.vehicle_text_rent {
  width: 15%;
  font-size: 14px !important;
}

.profile_name_resident_page {
  width: 30%;
  font-size: 14px !important;
}

.user_name_apartment {
  font-size: 14px !important;
}

.apt_num_text_resident {
  width: 15%;
  font-size: 13px;
  color: #1d7def;
  text-decoration: underline;
  cursor: pointer;
}
.apt_number_apartment_text {
  font-size: 14px !important;
  padding-left: 6px;
  width: 25%;
  cursor: pointer;
}
.apartment_rent_amount {
  font-size: 13px;
  width: 25%;
  cursor: pointer;
}

.apartment_status {
  width: 20%;
  font-size: 13px;
  cursor: pointer;
}

.status_text_apartment {
  width: 20%;
  font-size: 14px !important;
  cursor: pointer;
}
.list_of_apartments_div {
  display: flex;
}

.rent_amount_apartment_text {
  padding-left: 5px;
  font-size: 14px !important;
  width: 25%;
  cursor: pointer;
}
.name_apartment_div {
  width: 30%;
  font-size: 12px;
  padding-left: 10px;
}

.apartment_input {
  width: 280px;
  margin-right: 12px;
  border-radius: 3px;
  border: 1px solid #aeaeae;
  padding: 7px;
  padding-left: 10px;
  font-size: 12px;
}
