/* ShiftGanttChart.css */

/* Style the container for the chart */
.shift-chart {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  /* Style the x-axis on the top */
  .x-axis {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: 10px; /* Add padding as needed */
    background-color: #EAEAEA; /* Set the background color */
  }
  
  .x-tick {
    flex: 1;
    text-align: center;
  }
  
  /* Style the y-axis on the left */
  .y-axis {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100px; /* Adjust as needed */
    padding: 10px; /* Add padding as needed */
  }
  
  .user-row {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Adjust as needed */
  }
  
  .user-section {
    height: 20px; /* Set the height of the user section */
    margin-right: 5px; /* Add margin between user sections */
    background-color: #0074D9; /* Set your desired color here */
  }
  
  /* Add any other styling as needed */
  