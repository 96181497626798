.police_main_div {
  padding: 20px;
  height: 100vh;
  display: flex;
  background-color: #eaeaea;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.police_liasion_main_page {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
  padding-bottom: 7%;
}
.incident_back {
  display: flex;
  width: 100%;
}
.incident_back_popup {
  background-color: black;
}
