.incident_action_main_div {
    padding: 20px;
    height: 100vh;
    display: flex;
    background-color: #EAEAEA;
    width: 80%;
  }
  .incident_action_main_page_showCriminal{
    background-color: white;
    padding: 20px;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    padding-bottom: 7%;
    width: 100%;
    overflow-y: auto;
  }
  .incident_action_main_page_criminalList{
    background-color: white;
    padding: 20px;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    padding-bottom: 7%;
    width: 100%;
    overflow-y: auto;
  }
  .incident_action_main_page{
    background-color: white;
    padding: 20px;
    height: 100%;
    border-radius: 10px;
    overflow: hidden;
    padding-bottom: 7%;
    width: 100%;
    overflow-y: auto;
  }
  .incident_back{
      display: flex;
      width:100%;
  }

  .addCriminal {
    background-color: white !important;
    height: 45vh !important;
    border-radius: 10px;
    overflow: hidden;
    width: 45vh !important;
    margin-left: 30%;
    margin-top: 20%;
    padding-bottom: 15% !important;
    padding-top: 2%;
  }

.criminalMessage {
  background-color: white !important;
  margin: 20px;
  padding-bottom: 20px;
  overflow-y: auto;
}