.maintenance_main_div {
  height: 100vh;
  display: flex;
  background-color: #eaeaea;
  width: 80%;
  overflow: hidden;
}
.maintenance_profile_main_page {
  width: 30%;
}
.request_page_main_div {
  width: 70%;
}
.col_maintenance_edit2 {
  width: 15%;
}